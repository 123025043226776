.chart {
  width: 100%;
  height: 400px;
}

.custom-tooltip {
  background-color: #222a;
  backdrop-filter: blur(10px);
  padding: 6px 9px;
  border-radius: 3px;
  line-height: 1.5;
}

.chart-title {
  text-align: center;
  margin-bottom: 0;
  margin-top: 15px;
}